// @import url('https://use.typekit.net/mrl1qhh.css');
// @import "../../node_modules/bootstrap/scss/bootstrap";
// @import '../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';

@import './vars';
@import './global';

@import './layout';
@import './typography';

body {
  font-family: 'OpenSans';
  margin: 0 !important;
  padding: 0 !important;;
}

.autoShow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autoShow:hover {
  white-space: normal;
  overflow: visible;
  width: 100%;
}

.help-icon a {
  margin-left: 0;
  margin-right: 0;
}
